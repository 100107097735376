:root {
  --main-color: #5c9a5a;
  --main-color-hover: #4d814b;
  --text-color: #3c3c3c;
  --error-color: #e53e3e;
  --error-color-hover: #9b2c2c;

  --text-sm: 0.875rem;
  --semibold: 600;
  --rounded: 0.25rem;
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
}

*,
::before,
::after {
  box-sizing: border-box; /* 1 */
  border-width: 0; /* 2 */
  border-style: solid; /* 2 */
  border-color: #e2e8f0; /* 2 */
}

/* Sections
   ========================================================================== */

/**
 * Set font
 */

body {
  font-family: 'Open Sans', sans-serif;
  color: var(--text-color);
  line-height: 1.5;
  margin: 0;
}

main {
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.main-unauth {
  margin-top: 5%;
  width: 600px;
}

section {
  height: 100%;
  padding: 2rem 2rem;
  display: flex;
  flex-direction: column;
  overflow: auto;
}

section > form {
  width: 60%;
}

@media only screen and (max-width: 1100px) {
  section > form {
    width: 100%;
  }
}

.section-unauth {
  height: unset;
  background-color: #fff;
  border-radius: var(--rounded);
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
}

.section-unauth > form {
  width: 100%;
}

h1 {
  font-size: 1.5rem !important;
  font-weight: var(--semibold) !important;
  margin-bottom: 0.67rem !important;
}

h2 {
  font-size: 1.25rem !important;
  margin-bottom: 1rem !important;
}

label {
  display: block;
  margin-bottom: 0.5rem;
}

form {
  padding: 1.25rem 0.5rem;
  display: block;
}

.page {
  height: 100vh;
  display: flex;
}

.page-unauth {
  justify-content: center;
  background-size: cover;
  background-image: url(../assets/login_background.jpg);
}

.row {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.button-bar {
  display: flex;
  margin: 1rem 0;
  padding-bottom: 20px;
}

.select {
  font-size: var(--text-sm);
  line-height: 1.25;
  border-radius: var(--rounded);
  border-width: 1px;
  padding: 0 0.5rem;
  color: #4a5568;
  background-color: white;
}

.select:focus {
  outline: 0;
}

.grid {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 0.75rem;
}

.grid:focus {
  outline: 0;
}

.grid-cell {
  flex: 1 1 0%;
  border-width: 1px;
  font-size: var(--text-sm);
  border-radius: var(--rounded);
  padding: 0.5rem 1rem;
}

/* Eingeins entwickelte Input */
.input-button-group {
  display: flex;
}

.input-button-group-option {
  border-width: 1px;
  border-right-width: 0;
  cursor: pointer;
  text-align: center;
  flex: 1 1 0%;
  font-size: var(--text-sm);
  padding: 0.5rem 1rem;
  background-color: #fff;
  color: #4a5568;
}

.input-button-group-option:focus {
  outline: 0;
}

.input-button-group-option:disabled {
  cursor: not-allowed;
}

.input-button-group-option:first-child {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.input-button-group-option:last-child {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  border-right-width: 1px;
}

.input-button-group-option-active {
  color: var(--main-color);
  font-weight: 700;
  background-color: #f7fafc;
}

/* Allgemeiner Input-Wrapper */
.input-group {
  display: block;
  margin-bottom: 1rem;
  margin-right: 10px;
}

.input-group-error {
  border: var(--error-color);
  color: var(--error-color);
}

.input-text {
  appearance: none;
  border-radius: var(--rounded);
  line-height: 1.25;
  padding: 0.5rem 0.75rem;
  color: #4a5568;
  width: 100%;
  border-width: 1px;
}

.input-text:hover {
  outline: 0;
}

.input-text-error {
  font-weight: var(--semibold);
  margin-top: 0.25rem;
  color: var(--error-color);
}

.input-textarea {
  border-width: 1px;
  appearance: none;
  border-radius: var(--rounded);
  width: 100%;
  padding: 0.5rem;
  line-height: 1.625;
  color: #4a5568;
}

.input-textarea:hover {
  outline: 0;
}

.input-radio {
  margin-right: 0.25rem 0.75rem;
}

.form-error {
  font-weight: var(--semibold);
  margin: 1rem 0;
  color: var(--error-color);
}

.kartei {
  display: flex;
  flex-grow: 1;
  border-radius: var(--rounded);
  align-items: stretch;
  margin: 0 1rem 1rem 0;
  padding: 0.75rem 0;
  overflow: hidden;
  border: 1px solid #e2e8f0;
  font-size: var(--text-sm);
  height: 100%;
}

.reiter {
  min-width: 40px;
  position: relative;
  border-right-width: 1px;
  padding: 0 0.75rem;
  height: 100%;
  overflow: auto;
}

.reiter-text {
  font-weight: var(--semibold);
}

.navigation {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 280px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
}

.navigation-icon {
  font-size: 1.125rem;
  margin-right: 1.25rem;
}

@media screen and (max-width: 1400px) {
  .navigation {
    width: 72px;
  }
}

.navigation-link {
  padding: 0.75rem 1.5rem;
  display: flex;
  align-items: center;
}

.navigation-link:hover {
  color: var(--main-color);
}

.navigation-link-active {
  background-color: #f7fafc;
  border-left-width: 4px;
  padding-left: 1.25rem;
  border-color: var(--main-color);
}

.registration-bar {
  background-image: repeating-linear-gradient(45deg, #fefcbf 0px, #fefcbf 20px, #fbf7b3c1 20px, #fbf7b3c1 40px);
  width: 100%;
  line-height: 2.2;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 1em;
}

.link-router {
  color: var(--main-color);
  margin-left: 0.5rem;
}

.link-router:hover {
  color: var(--main-color-hover);
  text-decoration: underline;
  cursor: pointer;
}


/**
 * Reset form element properties that are easy to forget to
 * style explicitly so you don't inadvertently introduce
 * styles that deviate from your design system. These styles
 * supplement a partial reset that is already applied by
 * normalize.css.
 */

 button,
 input,
 optgroup,
 select,
 textarea {
   padding: 0;
   line-height: inherit;
   color: inherit;
 }
 

/**
 * 1. Change the font styles in all browsers.
 * 2. Remove the margin in Firefox and Safari.
 */

 button,
 input,
 optgroup,
 select,
 textarea {
   font-family: inherit; /* 1 */
   font-size: 100%; /* 1 */
   line-height: 1.15; /* 1 */
   margin: 0; /* 2 */
 }

.button {
  white-space: nowrap;
  border-radius: 0.25rem;
  font-weight: 600;
  padding: 0.5rem 1rem;
  margin-right: 1rem;
  height: 40px;
}

.button:hover {
  outline: 0;
  box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5);
}

.button-size-small {
  font-size: var(--text-sm);
  color: white;
}

.button-type-default {
  background-color: var(--main-color);
  color: white;
}

.button-type-default:hover {
  background-color: var(--main-color-hover);
}

.button-type-secondary {
  background-color: #edf2f7;
  color: #4a5568;
}

.button-type-secondary:hover {
  background-color: #e2e8f0;
}

.button-type-link {
  background-color: var(--main-color);
}

.button-type-link:hover {
  background-color: var(--main-color-hover);
}

.button-link {
  display: inline-block;
  font-weight: var(--semibold);
  padding: 0.5rem 1rem;
  border-radius: var(--rounded);
  white-space: nowrap;
  background-color: var(--main-color);
  color: white;
}

.button-link:hover {
  background-color: var(--main-color-hover);
}

.button-link:focus {
  outline: 0;
  box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5);
}

.link-bar {
  margin-top: 0.75rem;
  margin-left: 0.75rem;
}

.link-bar > a {
  color: #fff;
  margin-right: 0.75rem;
}

.link-bar > a:hover {
  text-decoration: underline;
}

/**
 * Reset links to optimize for opt-in styling instead of
 * opt-out.
 */

 a {
  color: inherit;
  text-decoration: inherit;
}

.link {
  color: var(--main-color);
}

.link:hover {
  cursor: pointer;
  text-decoration: underline;
}

.generator {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 2rem 2rem;
  height: 96vh;
}

.generator-layout {
  display: flex;
  flex-wrap: wrap;
  flex-grow: 1;
  height: 100%;
}

.generator-text {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.generator-textarea {
  max-width: 650px;
  font-size: var(--text-sm);
  flex-grow: 1;
}

.generator-navigation {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
}

.dnd-element {
  display: flex;
  align-items: center;
  padding: 0.5rem;
}

.dnd-icon {
  margin-right: 0.5rem;
  cursor: pointer;
}

.dnd-text {
  margin-right: 0.5rem;
}

.dnd-button {
  color: var(--error-color);
  font-weight: var(--semibold);
  font-size: var(--text-sm);
  margin: 0 1rem;
}

.dnd-button:hover {
  color: var(--error-color-hover);
}

.assessment-group {
  margin-right: 0.5rem;
}

/* Tabellen
   ========================================================================== */

.table {
  margin: 1rem 0;
  display: flex !important;
  flex-direction: column;
}

.table-head {
  font-weight: var(--semibold);
  display: flex !important;
  justify-content: space-between;
  border-bottom-width: 1px;
}

.table-row {
  border-bottom-width: 1px;
  display: flex !important;
  justify-content: space-between;
}

.table-cell {
  padding: 0.75rem;
}

/* Sonstiges
   ========================================================================== */

.terms-and-conditions {
  font-size: var(--text-sm);
  margin: 1rem 0;
  opacity: 0.75;
}

.icon {
  font-size: 1.125rem;
}

.highlight {
  color: var(--main-color);
}

.spacer {
  height: 1.25rem;
}

.spinner {
  -webkit-animation: rotator 1.4s linear infinite;
  animation: rotator 1.4s linear infinite;
  margin: auto;

  @-webkit-keyframes rotator {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(270deg);
      transform: rotate(270deg);
    }
  }

  @keyframes rotator {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(270deg);
      transform: rotate(270deg);
    }
  }
  .path {
    stroke-dasharray: 187;
    stroke-dashoffset: 0;
    -webkit-transform-origin: center;
    -ms-transform-origin: center;
    transform-origin: center;
    -webkit-animation: dash 1.4s ease-in-out infinite;
    animation: dash 1.4s ease-in-out infinite;
    stroke: #38a169;
  }

  @-webkit-keyframes dash {
    0% {
      stroke-dashoffset: 187;
    }
    50% {
      stroke-dashoffset: 46.75;
      -webkit-transform: rotate(135deg);
      transform: rotate(135deg);
    }
    100% {
      stroke-dashoffset: 187;
      -webkit-transform: rotate(450deg);
      transform: rotate(450deg);
    }
  }
  @keyframes dash {
    0% {
      stroke-dashoffset: 187;
    }
    50% {
      stroke-dashoffset: 46.75;
      -webkit-transform: rotate(135deg);
      transform: rotate(135deg);
    }
    100% {
      stroke-dashoffset: 187;
      -webkit-transform: rotate(450deg);
      transform: rotate(450deg);
    }
  }
}

.grow1 {
  flex-grow: 1;
}
.grow2 {
  flex-grow: 2;
}
.grow3 {
  flex-grow: 3;
}
.grow4 {
  flex-grow: 4;
}
.grow5 {
  flex-grow: 5;
}
.grow6 {
  flex-grow: 6;
}

.w20 {
  width: 20%;
}
.w25 {
  width: 25%;
}
.w40 {
  width: 40%;
}
.w50 {
  width: 50%;
}
.w60 {
  width: 60%;
}
.w75 {
  width: 75%;
}
.w80 {
  width: 80%;
}
.w100 {
  width: 100%;
}

.flex {
  display: flex;
}

/* Price Box
   ========================================================================== */

.price-box {
  width: 270px;
  max-height: 500px;
  margin-left: 50px;
  background-color: #f7f7f7;
}

.price-box-header {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 70px;
  background-color: var(--main-color);
  color: white;
  font-weight: bold;
  font-size: 1.3em;
}

.price-box-body {
  padding: 20px;
}

.price-box-price {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 160px;
  color: #555;
}

.price-box-price-value {
  font-size: 65px;
  font-weight: bold;
}

.price-box-price-period {
  margin-top: -16px;
}

.price-box-features > ul > li {
  display: flex;
  padding-bottom: 10px;
}

.price-box-feature-icon {
  font-size: 24px;
  font-weight: bold;
}

.price-box-feature-description {
  padding-left: 10px;
}

/* Fonts
   ========================================================================== */

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: local('Open Sans Regular'), local('OpenSans-Regular'),
    url('../assets/fonts/open-sans-v17-latin-regular.woff2') format('woff2');
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  src: local('Open Sans SemiBold'), local('OpenSans-SemiBold'),
    url('../assets/fonts/open-sans-v17-latin-600.woff2') format('woff2');
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  src: local('Open Sans Bold'), local('OpenSans-Bold'),
    url('../assets/fonts/open-sans-v17-latin-700.woff2') format('woff2');
}

@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url('../assets/fonts/icons.woff2') format('woff2');
}
